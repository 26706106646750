
/* .colSocialIcons {
    flex: 0 0 80%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
} */

.socialIconsTop {
    text-align: right;
    display: flex;
    justify-content: space-evenly;
    padding: 3vh 5vw;
}

.socialIconsTop .btn {
    position: relative;
    height: 6rem;
    min-width: 4rem;
    width: 6rem;
    font-size: 3.3rem;
    background-color: #fff;
    border: solid 1px #000;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.socialIconsTop .btn:hover i {
    color: #fff;
}

.socialIconsTop a {
    animation: right-t-left 1s;
    transform: translate(3000%,0%);
    animation-fill-mode: forwards;
    will-change: transform;
    font-weight: 400;
    margin: 5px 1px;
    border: none;
    cursor: pointer;
}

.socialIconsTop a:nth-child(1) {
    animation-delay:1.6s
}

.socialIconsTop a:nth-child(2) {
    animation-delay:1.8s
}

.socialIconsTop a:nth-child(3) {
    animation-delay:2s
}

.socialIconsTop a:nth-child(4) {
    animation-delay:2.2s
}

.socialIconsTop a:nth-child(5) {
    animation-delay:2.4s
}

.socialIconsTop a:nth-child(6) {
    animation-delay:2.6s
}

.socialIconsTop a:nth-child(7) {
    animation-delay:2.8s
}

.socialIconsTop a:nth-child(8) {
    animation-delay:3s
}

.socialIconsTop a:nth-child(9) {
    animation-delay:3.2s
}

.socialIconsTop a:nth-child(10) {
    animation-delay:3.4s
}

.socialIconsTop a:nth-child(11) {
    animation-delay:3.6s
}

@keyframes right-t-left {
    0% {
     transform:translate(2000%,0%)
    }
    100% {
     transform:translate(0%,0%)
    }
}

.fab {
    margin-top: 17px;
    font-family: "Font Awesome 5 Brands";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fas {
    margin-top: 17px;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fagitlab {
    color: #e24329;
}

.gitlab:hover {
    background-color: #e24329;
}

.fagithub {
    color: #000;
}

.github:hover {
    background-color: #000;
}

.fatwitter {
    color: #1da1f2;
}

.twitter:hover {
    background-color: #1da1f2;
}

.fayoutube {
    color: #dd2c00;
}

.youtube:hover {
    background-color:#dd2c00;
}

.fafacebook {
    color: #44609c;
}

.facebook:hover {
    background-color: #44609c;
}

.falinkedin {
    color: #0073b1;
}

.linkedin:hover {
    background-color: #0073b1;
}

.fafreecodecamp {
    color: #000;
}

.freecodecamp:hover {
    background-color: #000;
}

.facodepen {
    color: #000;
}

.codepen:hover {
    background-color: #000;
}

.fapaperplane {
    color: #ff9a4c;
}

.paperplane:hover {
    background-color: #ff9a4c;
}

.famobilealt {
    color: #d81b60;
}

.mobilealt:hover {
    background-color: #d81b60;
}

.faglobe {
    color:#35bf5c;
}

.globe:hover {
    background-color:#35bf5c;
}

@media screen and (max-width: 575px) {
    .gitlab,
    .github,
    .twitter,
    .youtube,
    .facebook,
    .linkedin,
    .codepen {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .gitlab,
    .github,
    .twitter,
    .youtube,
    .facebook,
    .linkedin,
    .codepen {
        display: none;
    }
}