.nav {
    animation: top-t-bottom 3s;
    animation-iteration-count: 1;
    will-change: transform;
    box-shadow: none;
    color: #FFFFFF;
    padding-top: 20px;
    font-weight: 700;
    font-family: roboto, sans-serif;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    transition: all 300ms ease 0s;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    flex-flow: row nowrap;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
}

@keyframes top-t-bottom {
    0% {
     transform:translate(0%,-800%)
    }
    100% {
     transform:translate(0%,0%)
    }
}

#navbar {
    background-color: #206418 !important;
}


#contact {
    overflow: hidden;
    padding: 80px 0px 60px 0px;
}

.outerContainer {
    max-width: 1200px;
    margin: auto;
}

.sectionTitle {
    text-align: center;
    padding-bottom: 40px;
    font-size: 2rem;
    font-weight: 500;
}

.contactSubtitle {
    text-align: center;
    margin: 10px 0px 70px;
    line-height: 50px;
    font-size: 1.2em;
}

/* .contact {
    max-width: 1200px;
    margin: auto;
    padding: 80px 0px 60px 0px;
    background-color: white;
}

.contact::after {
    content: '';
    display: inline-block;
    width: 100%;
}

.contact h2 {
    font-size: 2rem;
    font-weight: 500;
} */

.spaceBetween {
    display: flex;
    justify-content: space-around;
    text-align: justify;
}

.item {
    display: inline-block;
}

.phone {
    background-color: #EFE5F3;
    color: #9B59B6;
}

.email {
    background-color: #E0EFF9;
    color: #3498DB;
}

.meet {
    background-color: #DFF7E9;
    color: #2ECC71;
    line-height: 25px;
}

.meet p {
    color: #707070;
    padding-top: 20px;
    line-height: 23px;
}

.contactBox {
    width: 30%;
    text-align: center;
    padding: 30px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

.contactBox i {
    font-size: 60px;
    padding-bottom: 30px;
}

.text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.aboutList {
    line-height: 1.6em;
    font-size: 0.8em;
}

.buttonsContainer {
    margin-top: 40px;
    text-align: center;
}

.buttonsContainer button {
    display: inline-block;
    margin: 0px 70px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 17px;
    padding: 20px 50px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.16);
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.16);
    cursor: pointer;
    transition: .3s ease;
    -webkit-transition: all 0.3s;
}

.cont {
    background-color: #3FA8FD;
}

.cv {
    background-color: #2FDC9C;
}