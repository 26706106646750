@import url(https://fonts.googleapis.com/css?family=Teko:300,400,500,600);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

/* html,body,div,span,h1,h2,h3,h4,h5,h6,p,em,img,strong,sub,sup,b,i,ol,ul,li,article,canvas,figure,footer,header,nav,section {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size :100%;
    vertical-align: baseline;
    background: transparent
} */

/* body { line-height: 1 } */

/* .background {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
} */

/* article,footer,header,nav,section { display: block } */

/* a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    text-decoration: none
} */

/* li { list-style: none } */

/* *, ::after, ::before {
    box-sizing: border-box;
} */

/* html,body{ 
    width:100%;
    height:100%;
    background:#FFF;
} */

/* body {
    font-family: open sans,sans-serif;
    letter-spacing: .01em;
    overflow-x: hidden;
    color: #2c2c2c;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
} */

/* #particles-js {
    width:100%;
    height:100%;
    position: absolute;
    background-color: #b61924; background-image: url(""); background-repeat: no-repeat; background-size: cover; background-position: 50% 50%;
}

@media screen and (max-width:575px) {
    #particles-js {
        height:45vh;
    }
} */

/* .wrapper {
    overflow-y: hidden;
    background-image: url(../public/river-otter-silhouette.svg);
    background-repeat: no-repeat;
    background-position: 20px 70px;
    background-attachment: fixed;
    background-size: contain;
    background-color: #fff;
}

@media screen and (max-width: 575px) {
    .wrapper {
        background-image: none;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        background-image: none;
    }
} */

/* @keyframes fadeIn {
    0% { opacity: 1;}
    20% { opacity: 0.9; }
    40% { opacity: 0.5; }
    60% { opacity: 0.3; }
    80% { opacity: 0; }
    100% { opacity: 0; display: none;}
} */

/* .fab.fa-css3-alt {
    color:#214ce5;    
}

.fab.fa-html5 {
    color:#e34f26;
}

.fab.fa-js-square {
    color:#f1de4f;
} */

/* .section {
    background-color: transparent;
    min-height: 90vh;
    padding: 2.5em 0;
    position: relative;

}

.section .container {
    background: transparent;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.section .container .row {
    height: 0;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
} */

.under-title {
    height: 30px;
}

.grey {
    background-color: #EBEBEB;
}