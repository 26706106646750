.nav {
    animation: top-t-bottom 3s;
    animation-iteration-count: 1;
    will-change: transform;
    box-shadow: none;
    color: #FFFFFF;
    padding-top: 20px;
    font-weight: 700;
    font-family: roboto, sans-serif;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    transition: all 300ms ease 0s;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    flex-flow: row nowrap;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
}

@keyframes top-t-bottom {
    0% {
     transform:translate(0%,-800%)
    }
    100% {
     transform:translate(0%,0%)
    }
}