.nav {
    animation: top-t-bottom 3s;
    animation-iteration-count: 1;
    will-change: transform;
    box-shadow: none;
    color: #FFFFFF;
    padding-top: 20px;
    font-weight: 700;
    font-family: roboto, sans-serif;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    transition: all 300ms ease 0s;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    flex-flow: row nowrap;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
}

@keyframes top-t-bottom {
    0% {
     transform:translate(0%,-800%)
    }
    100% {
     transform:translate(0%,0%)
    }
}


#navbar {
    background-color: #206418 !important;
}

.apropos {
    max-width: 1200px;
    margin: auto;
    padding: 80px 0px 60px 0px;
    background-color: white;
}

.apropos::after {
    content: '';
    display: inline-block;
    width: 100%;
}

.apropos h2 {
    font-size: 2rem;
    font-weight: 500;
}

.spaceBetween {
    display: flex;
    justify-content: space-around;
    text-align: justify;
}

.item {
    display: inline-block;
}

.box1 {
    width: 26%;
}

.imgContainer {
    position: relative;
    overflow: hidden;
    width: 300px;
    height: 400px;
}

.imgContainer img {
    position: absolute;
    width: 300px;
}

.imgOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
}

.imgContainer:hover .imgOverlay {
    opacity: 1;
}

.box1 p {
    font-weight: 300;
    font-size: 1.1em;
    padding-top: 10px;
    line-height: 30px;
}

.box2 {
    vertical-align: top;
    width: 70%;
}

.box2 p {
    font-weight: 300;
    font-size: 1.3em;
    padding-top: 10px;
    line-height: 30px;
}

.box3 {
    display: none;
}

.box2 h2 {
    padding-bottom: 25px;
}

.box2 p {
    padding: 10px 0px;
    line-height: 30px;
}

.aboutList {
    line-height: 1.6em;
    font-size: 0.8em;
}


.buttonsContainer {
    margin-top: 1vh;
    text-align: center;
}

.buttonsContainer button {
    display: inline-block;
    margin-bottom: 5px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    padding: 5px 15px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.16);
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.16);
    cursor: pointer;
    transition: .3s ease;
    -webkit-transition: all 0.3s;
}

.cont {
    background-color: #3FA8FD;
}

.cv {
    background-color: #2FDC9C;
}