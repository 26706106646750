
.competencesIcons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10vh;
}

.item {
    color: #FFF;
    font-size: 1.1rem;
    display: inline-block;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.fas {
    margin-top: 19px;
    font-family: "Font Awesome 5 Free";
    display: block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: 4em;
    margin-bottom: .25em;
}

.competencesIcons div {
    animation: right-t-left 1s;
    transform: translate(3000%,0%);
    animation-fill-mode: forwards;
    will-change: transform;
    font-weight: 400;
    margin: 5px 1px;
    border: none;
}

@keyframes right-t-left {
    0% {
     transform:translate(2000%,0%)
    }
    100% {
     transform:translate(0%,0%)
    }
}

.competencesIcons div:nth-child(1) {
    animation-delay:1.6s
}

.competencesIcons div:nth-child(2) {
    animation-delay:1.8s
}

.competencesIcons div:nth-child(3) {
    animation-delay:2s
}

.competencesIcons div:nth-child(4) {
    animation-delay:2.2s
}

.code:hover {
    color: #9B59B6;
}

.database:hover {
    color: #3498DB;
}

.mobile:hover {
    color: #e74c3c;
}

.pencil:hover {
    color: #2ECC71;
}