.nav {
    animation: top-t-bottom 3s;
    animation-iteration-count: 1;
    will-change: transform;
    box-shadow: none;
    color: #FFFFFF;
    padding-top: 20px;
    font-weight: 700;
    font-family: roboto, sans-serif;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    transition: all 300ms ease 0s;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    flex-flow: row nowrap;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
}

@keyframes top-t-bottom {
    0% {
     transform:translate(0%,-800%)
    }
    100% {
     transform:translate(0%,0%)
    }
}


#navbar {
    background-color: #206418 !important;
}

#projets {
    background-color: #EBEBEB;
    padding: 80px 0px 60px 0px;
}

.outerContainer {
    width: 100%;
    margin: auto;
}

.sectionTitle {
    position: fixed;
    width: 100%;
    text-align: center;
    padding-bottom: 40px;
    font-size: 2rem;
    font-weight: 500;
}

#projets .tabs {
    top: 21vh;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1200px;
    margin: 0 auto;
}


.box {
    height: 450vh;
    position: relative;
    display: flex;
    align-items: center;
}

.box ul {
    position: fixed;
    top: 15rem;
    left: calc(10rem + 15vw);
    height: 40vh;
    display: flex;
    color: white;
    opacity: 1;
    transform: translateX(0px);
}

.box li {
    width: 35rem;
    height: 60vh;
    background-color: rgb(252, 246, 244);
    color: rgb(0, 0, 0);
    margin-right: 2rem;
    border-radius: 0 40px 0 37px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid rgb(0, 0, 0);
    transition: all 0.2s ease;
    transform: none;
}

.box .li:hover{
    background-color: rgb(0, 0, 0);
    color: rgb(252, 246, 244);
    border: 1px solid rgb(252, 246, 244);
}

h2 {
    font-size: 1em;
    text-align: center;
    color: #292929;
    padding-top: 1em;
    height: 4vh;
}

h4 {
    font-size: 0.8em;
    font-weight: 200;
    margin: 0 2em;
    height: 10vh;
}

.box img {
    height: 40vh;
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

/* ${Box}:hover &{
    border-top: 2px solid ${props =>props.theme.text};
} */

.tag {
    margin: 5px;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1;
    color: white;
    background-color: #d91c1c;
    padding: 5px;
    border-radius: 15px;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.link {
    background-color: #44883c;
    color: rgb(252, 246, 244);
    text-decoration: none;
    padding: 0.2em 5rem;
    border-radius: 0 0 0 50px;
    font-size: 1.2em;
}

/* ${Box}:hover &{
    background-color: ${props =>props.theme.text};
    color: ${props =>props.theme.body};

} */

.hidden {
    display: none;
}

.git {
    color: inherit;
    text-decoration: none;
    font-size: 2em;
    margin: -5px 1em -5px 0px;
}

.git i {
    width: 30px;
    height:30px;
}

/* ${Box}:hover &{
    &>*{
        fill:${props =>props.theme.text};
    }
} */


#projects h1 {
    font-size: 2em;
    text-align: center;
    padding: 0.5em;
    border: solid 2px;
    border-radius: 100px;
    margin: 1em 5em;
    background-color: #fff;
    font-family: roboto;
    font-weight: lighter;
    color: #2c2c2c;
}

@media screen and (max-width: 575px) {
    #projects h1 {
        margin: 1em;
    }
}

#projects h1 i {
    color: #35bf5c;
}


#projects .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 20px;
}

@media screen and (max-width: 768px) {
    #projects .container {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
}

#projects .container > a {
    padding: 10px;
    background-color: whitesmoke;
    box-shadow: 0 5px 25px 0 rgba(0,0,0,.5);
}

.project-image {
    width: 100%;
    height: calc(100% - 2.8rem);
    object-fit: cover;
}

.project-image-overlay h3 {
    text-align: center;
    font-size: 1.5em;
    font-family: roboto;
    font-weight: lighter;
    color: #2c2c2c;
}
.BuildATributePage iframe,
.BuildASurveyForm iframe,
.BuildAProductLandingPage iframe,
.BuildATechnicalDocumentationPage iframe,
.BuildAPersonalPortfolioWebpage iframe,
.WildCircus iframe {
    height: 99vh;
    padding-top: 64px;
}


.BuildARandomQuoteMachine iframe,
.BuildAMarkdownPreviewer iframe,
.BuildADrumMachine iframe,
.BuildAJavaScriptCalculator iframe,
.BuildA255Clock iframe {
    height: 99vh;
    padding-top: 64px;
}

.VisualizeDataWithABarChart iframe,
.VisualizeDataWithAScatterplotGraph iframe,
.VisualizeDataWithAHeatMap iframe,
.VisualizeDataWithAChoroplethMap iframe,
.VisualizeDataWithATreemapDiagram iframe {
    height: 99vh;
    padding-top: 64px;
}

.TimestampMicroservice iframe,
.RequestHeaderParserMicroservice iframe,
.URLShortenerMicroservice iframe,
.ExerciseTracker iframe,
.FileMetadataMicroservice iframe {
    height: 99vh;
    padding-top: 64px;
    width: 100%;
}

.TransformerUneMaquetteEnSite iframe,
.DynamiserUnePageWebAvecDesAnimationsCSS iframe,
.OptimiserUnSiteWebExistant iframe,
.ConstruireUnSiteECommerceEnJavaScript iframe,
.ConstruireUneAPISecuriseePourUneApplicationDAvisGastronomiques iframe {
    height: 99vh;
    padding-top: 64px;
    width: 100%;
}