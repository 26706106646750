
#back-to-top {
    background: rgb(68, 136, 60);
    border-radius: 50%;
    bottom: 20px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    color: #fff;
    cursor: pointer;
    display: block;
    height: 56px;
    opacity: 1;
    outline: 0;
    position: fixed;
    right: 20px;
    transition: bottom .2s,opacity .2s;
    user-select: none;
    width: 56px;
    z-index: 99;
}

#back-to-top svg {
    display: block;
    fill: currentColor;
    height: 24px;
    margin: 16px auto 0;
    width: 24px;
}

#back-to-top.hidden {

    opacity:0
}

svg {
    overflow: hidden;
    vertical-align: middle;
}
